<template>
  <div>
    <div class="pititle">
      <el-form>
        <div class="litop">
          <el-row>
            <el-col :span="4">
              <div class="grid-content bg-purple">
                <el-form-item label="项目名称">
                  <el-input
                    v-model="form.name"
                    placeholder="请输入项目名称"
                    @blur="saveSearchInSession"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple-light">
                <el-form-item label="测评机构">
                  <el-input
                    v-model="form.test_company"
                    placeholder="请输入测评机构"
                    @blur="saveSearchInSession"
                  ></el-input>
                </el-form-item></div
            ></el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple">
                <el-form-item label="批次编号">
                  <el-input
                    v-model="form.batch"
                    placeholder="请输入批次编号"
                    @blur="saveSearchInSession"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple">
                <el-form-item label="标识星级">
                  <el-select
                    v-model="form.after_stars"
                    placeholder="测评星级"
                    @blur="saveSearchInSession"
                  >
                    <el-option label="★" :value="1"></el-option>
                    <el-option label="★★" :value="2"></el-option>
                    <el-option label="★★★" :value="3"></el-option>
                  </el-select>
                </el-form-item></div
            ></el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple-light">
                <el-form-item label="审查专家">
                  <el-select
                    v-model="form.zid"
                    placeholder="请输入专家"
                    @blur="saveSearchInSession"
                  >
                    <el-option
                      v-for="item in expert_list"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item></div
            ></el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple-light">
                <el-button type="primary" @click="search">查询</el-button>
                <span>共{{ totalCount }}条</span>
              </div>
              <div class="gsbtn">
                <el-button type="warning" @click="pass">进入待公示</el-button>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2">
              <div class="grid-content bg-purple">
                <el-button @click="show_add_batch" type="warning"
                  >修改批次</el-button
                >
              </div> 
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="piconent">
      <!-- 表格 -->
      <div class="pitable">
        <el-table
          :data="tableData"
          stripe
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          style="width: 100%"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            label="批次编号"
            prop="batch"
            align="center"
            width="180"
          >
          </el-table-column>
          <el-table-column
            label="项目名称"
            prop="name"
            align="center"
            mix-width="250"
          >
            <template slot-scope="{ row }">
              <router-link
                :to="{
                  path:
                    '/technology/techInformation?id=' + row.id + '&active=1',
                }"
                class="detailPush"
              >
                <span>{{ row.name }}</span>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column label="建筑类型" align="center" width="180">
            <template slot-scope="{ row }">
              <span v-if="row.building_type == 1">公共建筑</span>
              <span v-if="row.building_type == 2">居住建筑</span>
            </template>
          </el-table-column>
          <el-table-column
            label="项目城市"
            align="center"
            prop="city"
            width="180"
          >
          </el-table-column>
          <el-table-column
            label="测评机构"
            align="center"
            prop="test_company"
            width="180"
          >
            <template slot-scope="{ row }">
              <span v-if="row.fname">{{ row.fname }}</span>
              <span v-else>{{ row.test_company }}</span>
            </template>
          </el-table-column>

          <el-table-column
            label="标识星级"
            align="center"
            prop="stars"
            width="100"
          >
            <template slot-scope="{ row }">
              <div v-if="row.after_stars == 0">无</div>
              <div v-else>
                <i
                  v-for="item in row.after_stars"
                  :key="item"
                  class="el-icon-star-on"
                ></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="标识阶段" align="center" width="180">
            <template slot-scope="{ row }">
              <span v-if="row.stage == 1">能效测评</span>
              <span v-else-if="row.stage == 2">实测评估</span>
            </template>
          </el-table-column>
          <el-table-column
            label="申报时间"
            align="center"
            prop="created_at"
            width="180"
          >
            <template slot-scope="{ row }">
              <span>{{ row.created_at | formatDate("yyyy-MM-dd") }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="审查专家"
            align="center"
            prop="zname"
            width="180"
          >
          </el-table-column>
          <el-table-column label="项目状态" align="center" width="180">
            <template slot-scope="{ row }">
              <router-link
                :to="{ path: '/technology/techInformation?id=' + row.id }"
                class="detailPush"
              >
                <span v-if="row.status == 1">未提交</span>
                <span v-else-if="row.status == 2">形式初审</span>
                <span v-else-if="row.status == 3">形式复审</span>
                <span v-else-if="row.status == 4">审查分配</span>
                <span v-else-if="row.status == 5">专家初审</span>
                <span v-else-if="row.status == 6">专家复审</span>
                <span v-else-if="row.status == 7" style="color: #e6a23c"
                  >专家反馈</span
                >
                <span v-else-if="row.status == 8" style="color: #67c23a"
                  >审核通过</span
                >
                <span v-else-if="row.status == 9">待公示</span>
                <span v-else-if="row.status == 10">公示中</span>
                <span v-else-if="row.status == 11">公告中</span>
                <span v-else-if="row.status == 12">待寄送</span>
                <span v-else-if="row.status == 13">待自取</span>
                <span v-else-if="row.status == 14">已完成</span>
                <span v-else-if="row.status == 15">形式审查待修改</span>
                <span v-else-if="row.status == 16">形式审查已修改</span>
                <span v-else-if="row.status == 17">技术审查待修改</span>
                <span v-else-if="row.status == 18">技术审查已修改</span>
                <span v-else-if="row.status == 19" style="color: #e6a23c"
                  >专家待复审</span
                >
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pageTotal">
      <div class="block">
        <el-pagination
          :current-page="page"
          :page-count="totalPage"
          :page-size="limit"
          :total="totalCount"
          background
          layout=" prev, pager, next, total, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- <div class="gsbtn">
            <el-button type="warning" @click="pass">进入待公示</el-button>
          </div> -->
    <el-dialog :visible.sync="batch_show" title="输入批次号" width="30%">
      <el-input v-model="batch" placeholder="请输入批次号"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batch_show = false">取 消</el-button>
        <el-button type="primary" @click="add_batch">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getProjectList,
  delProject,
  exportList,
  changeStatus,
  addBatch
} from "../../../../api/project";
export default {
  filters: {
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      form: {},
      page: 1,
      limit: 10,
      totalCount: 0,
      totalPage: 1,
      tableData: [],
      checked_list: [],

      //专家列表
      expert_list: [],
       batch: "",
      batch_show: false,
    };
  },
  mounted() {
    this.getSearchInSession();
    this.getList();
    this.getExportList();
  },
  methods: {
    add_batch() {
      let that = this;
      let ids = this.checked_list.map((item) => {
        return item.id;
      });
      if (this.batch && this.batch != "") {
        let params = {
          ids: ids,
          batch: this.batch,
          token: this.$store.state.token,
        };
        addBatch(params).then((res) => {
          console.log(res);
          that.batch_show = false;
          that.getList();
        });
      } else {
        this.$message.warning("请填写批次号");
      }
    },
    show_add_batch() {
      if (this.checked_list.length > 0) {
        this.batch_show = true;
      } else {
        this.$message.warning("请选择项目");
      }
    },
    //批量通过
    pass() {
      let ids = [];
      if (this.checked_list && this.checked_list.length > 0) {
        for (let i = 0; i < this.checked_list.length; i++) {
          if (this.checked_list[i].status != 8) {
            this.$message.warning("选中数据必须全部是审核通过状态！");
            return false;
          } else {
            ids.push(this.checked_list[i].id);
          }
        }
      } else {
        this.$message.warning("至少选中一条数据！");
        return false;
      }
      let params = {
        ids: ids,
        status: 9,
        token: this.$store.state.token,
      };
      let that = this;
      changeStatus(params).then((res) => {
        console.log(res);
        that.$message.success("成功通过");
        that.getList();
      });
    },

    saveSearchInSession() {
      if (this.search_form) {
        sessionStorage.setItem("tech_search", JSON.stringify(this.search_form));
      }
    },
    getSearchInSession() {
      let searchForm = sessionStorage.getItem("tech_search");
      if (searchForm && searchForm != "") {
        this.search_form = JSON.parse(searchForm);
      }
    },
    //获得专家
    getExportList() {
      let params = {
        page: 1,
        limit: 1000,
        token: this.$store.state.token,
      };
      if (this.search_expert_name != "") {
        params.name = this.search_expert_name;
      }
      let that = this;
      exportList(params).then((res) => {
        that.expert_list = res.data;
      });
    },

    handleSelectionChange(val) {
      this.checked_list = val;
    },

    //删除数据
    check_del() {
      let that = this;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = this.checked_list.map((item) => {
            return item.id;
          });
          // console.log(ids);
          let params = {
            token: this.$store.state.token,
            id: ids,
          };
          delProject(params).then((res) => {
            console.log(res);
            that.getList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //获得表格数据
    getList() {
      let that = this;

      let params = this.form;
      params.page = this.page;
      params.limit = this.limit;
      params.status = "5,6,7,8,16,17,18,19";
      params.skill = 1;
      params.token = this.$store.state.token;
      getProjectList(params)
        .then((res) => {
          // console.log(res);
          that.tableData = res.data;
          that.page = res.page;
          that.totalCount = res.totalCount;
          that.totalPage = res.totalPage;
          if (!that.tableData || that.tableData.length == 0) {
            this.$message.warning("暂无数据");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //搜索
    search() {
      this.page = 1;
      this.getList();
    },

    // 分页
    handleSizeChange(page) {
      this.limit = page;
      this.getList();
      //console.log(`每页 ${page} 条`)
    },
    handleCurrentChange(page) {
      //console.log(`当前页: ${page}`)
      this.page = page;
      this.getList();
    },
  },
};
</script>
<style scoped>
/deep/.el-form-item__label {
  font-size: 18px;
}
/deep/ .litop .el-input {
  width: 174px;
}

/deep/ .litop .el-input__inner {
  width: 172px;
}
/deep/.el-table th.el-table__cell > .cell {
  color: #3086fb;
  font-weight: bold;
  font-size: 18px;
}
.gsbtn {
  display: flex;
  flex-direction: row-reverse;
  margin: -39px 20px;
}
.detailPush {
  text-decoration: none;
  color: #3086fb;
}
.pitable {
  height: 60vh;
}
.litop {
  margin: 20px 24px;
}
.litop span {
  color: #3086fb;
  margin-left: 10px;
  line-height: 40px;
}
.prbtn {
  display: flex;
  flex-direction: row-reverse;
}
.pageTotal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 38px;
}
.pititle {
  padding: 30px 0;
}
.piconent {
  height: 80vh;
}
</style>
